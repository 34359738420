import React, { Component, Fragment } from 'react';
import Hero from './components/Hero';
import Contact from './components/Contact';
import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import './styles/site.css';

const alertDefaultConfig = {
  position: 'top',
  effect: 'scale',
  timeout: 5000,
}

class App extends Component {
  componentDidMount() {
    console.info("Hey! How's it going?");
  }
  render() {
    return (
      <Fragment>
        <section id="hero">
          <Hero />
        </section>
        <section id="about">

        </section>
        <section className="contact">

          <Contact />
        </section>
        <Alert {...alertDefaultConfig} stack={{limit: 1}} />
      </Fragment>
    );
  }
}

export default App;
