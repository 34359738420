import React, { Component } from "react";
import Alert from "react-s-alert";
import { sendEmail } from "../lib/email";

export default class Contact extends Component {
  submitForm = e => {
    e.preventDefault();

    var name = e.target.name.value;
    var email = e.target.email.value;
    var phone = e.target.phone.value;
    var message = e.target.message.value;

    if (!email && !phone) {
      Alert.warning("Please provide a way to contact you!");
      return false;
    }

    sendEmail({
      name,
      email,
      phone,
      message
    }).then(
      () => {
        Alert.success("Sent! Thank you for your interest!");
        document.getElementById("contact-form").reset();
      },
      () => {
        Alert.warning("Something went wrong!");
      }
    );
  };
  render() {
    return (
      <div className="contact-form" id="contact-form-container">
        <h1>Contact</h1>
        <form
          method="post"
          className="ajax_form"
          onSubmit={this.submitForm}
          noValidate=""
          id="contact-form"
        >
          <div className="form">
            <div className="form-left">
              <p>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="First &amp; Last Name"
                />
              </p>

              <p>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="contact-email"
                  placeholder="Your Email"
                />
              </p>

              <p>
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Best Number to Reach You"
                />
              </p>
            </div>

            <div className="form-right">
              <p>
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  rows="15"
                  placeholder="What you'd like created or what you're curious about..."
                ></textarea>
              </p>
            </div>
          </div>

          <p className="submit">
            <button>Send Message</button>
          </p>

          <input
            type="hidden"
            name="af_action"
            value="05c8101311bd3e024a4ff85c470fed55"
          />
        </form>
      </div>
    );
  }
}
