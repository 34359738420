import React, { Component } from "react";
import { scroller } from "react-scroll";

export default class Hero extends Component {
  render() {
    return (
      <div className="poly-hero" data-stellar-background-ratio="0.5">
        <div className="hero-content">
          <h1>
            <img src="img/logo-color.png" className="logo" alt="Ship B" />
          </h1>
          <h2>Web + Mobile + System Integration</h2>

          <div id="cta">
            <div className="statement">
              <h3>
                Your business is <span>evolving</span>. Your users are{" "}
                <span>changing</span>.
              </h3>
              <p>
                We help our clients implement the tools and processes to enable
                continual digital growth.
              </p>
              <a
                onClick={e => {
                  e.preventDefault();
                  scroller.scrollTo("contact-form-container", {
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart"
                  });
                }}
                href="#contact-form"
                className="button"
              >
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
